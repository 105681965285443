/*
CUSTOM COLOUR VARIABLES
Add key: value pairs for custom colours below.
A class of the colour name (key) will be created for background-color.
A class appended with '-text' will be created for text-color.
*/
$custom_colors: (
    bernie-yellow: #FFBE1C,
	dark-gray: #212121
);
$bernie-yellow: map-get($custom_colors, "bernie-yellow");
$dark-gray: map-get($custom_colors, "dark-gray");

@import "@materializecss/materialize/sass/components/color-variables";
$primary-color: $bernie-yellow;
$secondary-color: $dark-gray;
$link-color: $bernie-yellow;
$card-link-color: $bernie-yellow;

/*
DO NOT REMOVE - AUTO CLASS CREATION
*/
@each $color_name, $color_value in $custom_colors {
    .#{$color_name} {
        background-color: $color_value !important;
    }
    .#{$color_name}-text {
        color: $color_value !important;
    }
}
/*
END AUTO CLASS CREATION
*/