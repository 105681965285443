@import './helper/variables';
@import '@materializecss/materialize/sass/materialize.scss';
@import '../../mvp/css/fixes/materialize.scss';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
/* Remove fancybox fix when libsass bug with env() is resolved */
@import '../../mvp/css/fixes/jquery.fancybox.css';
/*@import '~@fancyapps/fancybox';*/

// Custom styling - you can use materialize helper variables here
body {
    @extend .white;
    @extend .grey-text;
    @extend .text-darken-4;
}

h1, h2, h3, h4, .h1_dummy, .h2_dummy, .h3_dummy, .h4_dummy {
    font-weight: bold;
}

h1 { font-size: 2em; margin-bottom: 0.5em; }
h2, .h2_dummy { font-size: 1.5em; }
h3, .h3_dummy { font-size: 1.25em; }
h4, .h4_dummy { font-size: 0.875em; }
h5, .h5_dummy { font-size: 0.75em; }
h6, .h6_dummy { font-size: 0.7em; }
.mvp_body h1:first-of-type {
    margin-top: 0;
}

header > .inner {
    margin-top: 1.5em;
}

menu nav{
	background-color: white;
	height: 100px;
	border-bottom: 4px solid $bernie-yellow;
}

menu .nav-wrapper{
    max-width: 1000px;
	margin-left: auto;
	margin-right: 0;
}

menu nav .nav-wrapper > ul {
	display: inline-block;
	float: right;
	max-width: 600px;
	width: 100%;
	height: 100px;
}

menu .brand-logo {
	display: inline-block;
	float: left;
	width: auto;
}

menu .brand-logo img{
	padding: 10px;
	height: 100%;
	max-height: 100px;
	width: auto;
	max-width: 100%;
}

menu nav ul:not(.dropdown-content) > li {
    width: calc(100%/5);
	margin-right: 12px;
}

menu nav ul:not(.dropdown-content) > li > a {
	font-weight: bold;
	color: $dark-gray;
	line-height: 96px;
	font-size: 18px;
	position: relative;
}

menu nav ul.dropdown-content li {
    text-align: center;
}
menu ul.mvp_menu_items:not(.mobile) > li > a i {
    display: none;
}

main {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.home-page {
	padding-top: 0em;
}
main .inner {
    max-width: 1280px;
    margin: 0 auto;
}

.social-icons svg path {
	fill: $bernie-yellow;
}

footer{
	background-color: $dark-gray !important;
}
footer a { color: #ddd; }
footer a:hover { color: #eee; }
footer > .inner .col:not(.mvp_body) {
    text-align: center;
}
footer .mvp_connected.mvp_menu li.submenu {
	display: inline-block;
    vertical-align: top;
	width: 50%;
}
footer .footer-copyright { font-size: 0.875em; }
footer .footer-copyright span {
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
}
footer .footer-copyright .legal {
	float: left;
}
footer .footer-copyright .web-developer {
	float: right;
}

.col-left img{
	border: 2px solid $bernie-yellow;
	border-radius: 100%;
}
/*.rowing-challenge img{
	margin-left: -30px;
	margin-right: -30px;
	width: auto;
	max-height: 200px;
	overflow: hidden;
}*/

.section-title {
	border-top: 4px solid $bernie-yellow;
	border-bottom: 4px solid $bernie-yellow;
	text-align: center;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding: 1em;
	position: relative;
	color: $dark-gray;
}
.section-title:after {
	content: '';
    position: absolute;
    left: 48%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $bernie-yellow;
    clear: both;
}
/*.section-title:hover {
	transform: scale(1.1);
}*/

.rowing-challenge {
	background-image: url("../images/defaults/Rowing-Challenge.jpg");
	height: 400px;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.gallery {
	display: flex;
	flex-wrap: wrap;
}

.gallery img {
	/*max-height: 100%;
	min-width: 100%;
    object-fit: cover;*/
	height: 100%;
	max-height: 400px;
	width: 100%;
	max-width: 400px;
}

.supports-slideshow{
	margin-top: 30px;
}
.supports-slideshow img{
	padding: 20px;
}

.section img{
	max-width: 420px;
	width: 100%;
	height: auto;
}

.cta-btn {
	background-color: $bernie-yellow;
	color: $dark-gray;
	margin-top: 20px;
	margin-bottom: 20px !important;
	font-weight: bold;
}
.cta-btn:hover {
	background-color: lighten($bernie-yellow, 5%) !important;
}
.cta-btn:visited {
	background-color: lighten($bernie-yellow, 5%) !important;
}

/*.slideshow_container {
	position: relative;
	z-index: 20;
	margin-top: -19px;
}
.slick-dots {
	font-family: arial, tahoma;
	font-weight: 400;
}
.slick-prev {
	left: 20px;
	z-index: 1000;
}
.slick-next {
	right: 20px;
	z-index: 1000;
}
.skip-slider-container {
	position: absolute;
	width: 100%;
	height: 40px;
	bottom: 20px;
	text-align: center;
}
.skip-slider {
	display: inline-block;
	height: 40px;
	width: 30px;
	border: 2px solid #fff;
}
.skip-slider:hover i {
	transition: margin 0.5s ease;
	margin-top: 14px;
}*/

.card-panel {
	transition: transform .5s ease;
}
.card-panel:hover{
	transform: scale(1.1);
}
.card-panel span{
	color: white;
}

.dark-background-block{
	margin: 50px 0px;
	background-color: rgba(49, 49, 49, 0.9);
	color: white;
	padding: 20px 80px !important;
}
.dark-background-block h2{
	color: white;
}

.light-background-block{
	margin: 70px 0px;
	background-color: rgba(255, 255, 255, 0.9);
	color: rgb(49, 49, 49);
	padding: 20px 80px !important;
}
.light-background-block h2{
	color: rgb(49, 49, 49);
}

.container {
    height: 600px;
}

.justifiedlayout .item {
    opacity: 1;
}

.justifiedlayout .item .thumbnail {
    overflow: hidden;
}

.justifiedlayout .item .thumbnail img {
    width: 100%;
}

.justifiedlayout .item .info {
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
    line-height: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
    text-align: left;
    font-size: 14px;
    opacity: 0;
    transition: opacity ease 0.2s;
}

.justifiedlayout .item:hover .info {
    opacity: 1;
}

.item.animate {
    transition: opacity ease 1s;
    transition-delay: 0.2s;
    opacity: 1;
}


.justifiedlayout .loading {
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    display: none;
}


.justifiedlayout .loading span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.justifiedlayout.horizontal .loading {
    width: 200px;
    height: 100%;
}

.home-page-message blockquote {
	font-style: normal !important;
	font-family: Roboto, Arial;
}
.home-page-message blockquote h2 {
	text-shadow: 1px 1px #333;
}

.cardLogos {
	text-align: center;
}
.cardLogos div {
	max-width: 220px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	max-height: 120px;
	height: 100%;
	line-height: 80px;
	padding: 20px !important;
	margin: 10px;
	border-radius: 15px;
	display: inline-block;
	vertical-align: middle;
}
.cardLogos div img{
	vertical-align: middle;
}

nav a.sidenav-trigger{
	color: $dark-gray;
	margin-top: 18px;
	float: right;
	z-index: 100 !important;
}

#mvp_menu_mobile.sidenav div.h2_dummy{
	border-bottom: 4px solid $bernie-yellow;
	padding-bottom: 41px;
}

#clockdiv{
    font-family: sans-serif;
    color: #fff;
    display: block;
    font-weight: 100;
    text-align: center;
    font-size: 30px;
}

#clockdiv > div{
    padding: 10px;
    border-radius: 3px;
    background: $bernie-yellow;
    display: inline-block;
}

#clockdiv div > span{
    padding: 15px;
    border-radius: 3px;
    background: $dark-gray;
    display: inline-block;
}

.smalltext{
    padding-top: 5px;
    font-size: 16px;
	color: $dark-gray;
}

.clockH3 {
	color: $dark-gray;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  body.bg-cover {
	background-attachment: scroll !important;
  }
}
@media #{$small-and-down} {
    /* small screens and down */
	menu nav .brand-logo {
		line-height: 56px;
	}
	.home-page-slideshow.zooming .zooming-image, .home-page-slideshow .slick-slide, .home-page-slideshow .zooming-image {
		height: 200px;
	}
	.feature-image {
		min-height: 460px !important;
	}
	.col.image_only img {
		border-radius: 0 !important;
	}
	.first {
		margin-top: 5vh !important;
		margin-bottom: 16vh !important;
	}	
	.light-background-block {
		padding: 20px 20px !important;
	}
	.dark-background-block {
		padding: 18px 18px !important;
	}
	nav.main-menu {
		position: fixed;
		z-index: 100;
	}
	body .inner{
		margin-top: 106px;
	}
	.rowing-challenge {
		background-attachment: scroll !important;
	 }
}
@media #{$medium-and-up} {
    /* medium screens and larger */
	.home-page-slideshow.zooming .zooming-image, .home-page-slideshow .slick-slide, .home-page-slideshow .zooming-image {
		height: 400px;
	}
	.feature-image {
		min-height: 600px !important;
	}
	.first {
		margin-top: 18vh !important;
		margin-bottom: 30vh !important;
	}
}
@media #{$medium-and-down} {
    /* medium screens and down */
	.feature-content {
		min-height: 0 !important;
	}
	.feature-content .mvp_body {
		position: relative;
		top: auto;
		transform: none;
		width: auto !important;
	}
	.highlight-content {
		min-height: 0 !important;
		font-size: 1em;
		margin-top: 1em;
		margin-bottom: 1em;
	}
	.highlight-content .mvp_body {
		position: relative;
		top: auto;
		transform: none;
		width: auto !important;
	}
	.col.image_only img {
		border-top-left-radius: 20px;
		border-bottom-right-radius: 20px;
	}
	.first {
		margin-top: 18vh !important;
		margin-bottom: 30vh !important;
	}
	menu .brand-logo{
		z-index: 99;
	}
	.contact_info{
		text-align: center;
	}
	.contact_info table {
		margin-left:auto;
		margin-right:auto;
	}
}
@media #{$large-and-up} {
    /* large screens and up */
	.home-page-slideshow.zooming .zooming-image, .home-page-slideshow .slick-slide, .home-page-slideshow .zooming-image{
		height: 500px;
	}
	.feature-image, .feature-content {
		min-height: 500px !important;
	}
	.col.image_only img {
		width: 100%;
	}	
}
@media #{$extra-large-and-up} {
    /* extra large screens and up */
	.feature-image, .feature-content {
		min-height: 600px !important;
	}
	.highlight-content .mvp_body {
		font-size: 0.875em;
	}
	.col.image_only img {
		width: auto;
	}

}
@media only screen and (min-width: 1600px) {
	/* xxl screens and up */
	.home-page-slideshow.zooming .zooming-image, .home-page-slideshow .slick-slide, .home-page-slideshow .zooming-image {
		height: 820px;
	}
	.feature-image, .feature-content {
		min-height: 700px !important;
	}
	.highlight-content .mvp_body {
		font-size: 1em;
	}
	.first {
		margin-top: 24vh !important;
		margin-bottom: 48vh !important;
	}
}
@media only screen and (max-width: 992px) {
	nav .brand-logo {
		left: auto;
		transform: none;
	}
}
@media only screen and (max-width: 600px) {
	menu nav .brand-logo {
		line-height: 88px;
		max-width: 80%;
	}
	menu .brand-logo img {
		height: auto;
		vertical-align: middle;
	}
}