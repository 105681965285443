/*
Copyright 2012-2019 SIGMA Technology Solutions Ltd <dev@sigmatechnology.co.uk>
This file is part of the Multiverse project. It can not be copied and/or
distributed without the express permission of the author.
*/
/*
    Created on : 22 May 2019, 18:14:08
    Author     : SIGMA Technology Solutions Ltd <dev@sigmatechnology.co.uk>
*/

/* Fixes and improvements for MaterializeCSS */

.chips .autocomplete-content {
    width: 100% !important;
    left: 0 !important;
}
.chips .input {
    height: auto;
}
.chip {
    vertical-align: top;
}

/* Labels are too faded in general */
label, .input-field > label {
    color: #333;
}
/* Heading labels for radio buttons / checkboxes. */
:not(.input-field) > label {
    font-size: 1rem !important;
}
/* Button spacing */
.btn, .btn-large, .btn-flat {
    margin-bottom: 0.375em !important;
}
nav ul a.btn, nav ul a.btn-large, nav ul a.btn-large, nav ul a.btn-flat, nav ul a.btn-floating {
    margin-bottom: 0 !important;
}
/* Button groups - remove spacing and shadow to create a row of buttons */
.btn-group {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
}
.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}
/* Fix carousel so it 'contains' the images */
.carousel .carousel-item {
    text-align: center !important;
}
.carousel .carousel-item:before {
    content: "";
    display: inline-block !important;
    height: 100% !important;
    vertical-align: middle !important;
}
.carousel .carousel-item > img {
    width: auto !important;
    max-height: 100% !important;
    vertical-align: middle !important;
}
/* Drop-down menu positioning. */
nav .nav-wrapper .dropdown-content {
    top: 100% !important;
}
/* Center-aligned Navbar Menu */
nav ul.nav-center {
    text-align: center !important;
}
nav ul.nav-center > li {
    display: inline !important;
    float: none !important;
}
nav ul.nav-center > li > a:not(.button-collapse) {
    display: inline-block !important;
}
nav ul.nav-center .button-collapse {
    float: none !important;
}
nav ul.nav-center.mobile > li {
    display: inline-block !important;
}
nav ul.nav-center.mobile a {
    margin: 0 !important;
    width: 100% !important;
}
nav ul.nav-center.mobile i {
    display: inline-block !important;
}
/* Mobile Menu Default Styling */
.mvp_menu_mobile {
    padding: 24px 0;
}
.mvp_menu_mobile .h2_dummy {
    padding: 0 30px;
}
.mvp_menu_mobile .mvp_body {
    padding: 0 30px;
    font-size: 0.875em;
}
.mvp_menu_mobile ul.collapsible a.collapsible-header {
    padding: 0 30px;
}
.mvp_menu_mobile ul.collapsible .collapsible-body li a {
    font-weight: 400;
    padding: 0 37.5px 0 45px;
}
.mvp_menu_mobile li > a > i.right {
    margin-right: 0 !important;
}
/* Make card title 100% width if it's over the image so a transparent bg can be applied easily. */
.card .card-image .card-title {
    width: 100% !important;
}
/* Extra Small Card Size - usually for horizontal */
.card.xsmall {
    position: relative;
    height: 160px;
    overflow: hidden;
}
/* Fix the card image heights so the whole image is displayed. */
.card .card-image {
    background-size: cover !important;
    background-position: center !important;
}
.card .card-image.contain img {
    width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.card.xsmall:not(.image-only) .card-image {
    min-height: 160px !important;
}
.card.xsmall:not(.image-only) .card-image.contain, .card.xsmall:not(.image-only) .card-image.contain img {
    max-height: 100px !important;
}
.card.small:not(.image-only) .card-image.contain, .card.small:not(.image-only) .card-image.contain img {
    max-height: 160px !important;
}    
.card.medium:not(.image-only) .card-image.contain, .card.medium:not(.image-only) .card-image.contain img {
    max-height: 200px !important;
}
.card.large:not(.image-only) .card-image.contain, .card.large:not(.image-only) .card-image.contain img {
    max-height: 280px !important;
}
/* Image-only cards - make image full height */
.card.image-only .card-image {
    max-height: 100% !important;
    height: 100% !important;
    text-align: center;
}
/* Fix badges used in tab headings */
.tabs .tab span.badge {
    min-width: auto !important;
    float: none !important;
    margin-left: 14px !important;
    padding: 2px 6px !important;
}
/* Tooltips */
.material-tooltip {
    max-width: 320px !important;
}
/* Forms */
.js-editor-field label {
    transform: translateY(-14px) scale(0.8) !important;
    transform-origin: 0 0 !important;
}
/* Responsive Modal Windows */
.modal:not(.datepicker-modal):not(.timepicker-modal) {
    max-width: 1200px !important;
    width: 98% !important;
    max-height: 80% !important;
    height: 100% !important;
}
.modal:not(.datepicker-modal):not(.timepicker-modal):not(.modal-fixed-footer):not(.full-height) {
    height: auto !important;
}
.modal.full-height .modal-content {
    position: absolute;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
}
.modal:not(.datepicker-modal):not(.timepicker-modal).sub-modal {
    top: 2% !important;
    max-height: 96% !important;
}
@media (max-width: 30em) {
    .modal {
        width: 100% !important;
        max-height: 90% !important;
        top: 5% !important;
    }
    .modal.modal-fixed-footer, .modal.full-height {
        height: 90% !important;
    }
    .modal .modal-content {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .modal .h2_dummy {
        text-align: center !important;
    }
    .modal .modal-content .collapsible .collapsible-body {
        padding: 1rem !important;
    }
    .modal *[class^="btn"] {
        float: none !important;
        display: inline-block !important;
        margin-bottom: 0.5em !important;
    }
    .modal .btn-flat.modal-close {
        display: none !important;
    }
}
/* Min height for inputs to improve floating layout */
.modal .modal-content .input-field:not(.file-field):not(.no-min-height) {
    min-height: 74px !important;
}
/* Character Counter reposition and color */
.input-field .character-counter {
    position: absolute !important;
    top: -14px !important;
    right: 12px !important;
    color: #9e9e9e !important;
}
/* Sub-field spacing fix */
.input-field.sub-field.no-padding-x label {
    left: 0;
}
.input-field.sub-field.no-padding-x .character-counter {
    right: 0 !important;
}
/* Re-add table styling that was removed in Materialize 1.1.0 */
table.striped > tbody > tr:nth-child(odd) {
    background-color: rgba(242, 242, 242, 0.5);
}
table.striped tr {
    border-bottom: none;
}